<template>
    <div class="sub-project">
        <header>
          <div class="logo">
              <a href="">
                  <img class="js-img" src="../../public/assets/img/logo-p-c.webp" alt="Logo" width="65px">
              </a>
          </div>
          <nav>
              <ul>
                  <li>
                      <a href="#home" @click.prevent="goMainPagewithSection(1)" id="link-home">
                          <p class="nav-number">01</p>
                          <p class="nav-menu">// home</p>
                      </a>
                  </li>
                  <li>
                      <a href="#expertise" @click.prevent="goMainPagewithSection(2)">
                          <p class="nav-number">02</p>
                          <p class="nav-menu">// expertise</p>
                      </a>
                  </li>
                  <li>
                      <a href="#project" @click.prevent="goMainPagewithSection(3)">
                          <p class="nav-number">03</p>
                          <p class="nav-menu">// projet</p>
                      </a>
                  </li>
                  <li>
                      <a href="#contact" @click.prevent="goMainPagewithSection(4)">
                          <p class="nav-number">04</p>
                          <p class="nav-menu">// contact</p>
                      </a>
                  </li>
              </ul>
          </nav>
          <div class="fake-right">
                    <button class="burger burger3" @click="navBurger">
                        <span></span>
                    </button>
            </div>
        </header>
        <a class="sub-project-back" href="" @click.prevent="goMainPage">
            <span class="sub-project-back-span"></span>
            <span class="sub-project-back-color"></span>
        </a>
        <div class="sub-project-content">
            <p class="sub-project-type">Développement Application avec Python</p>
            <h1>Projet Taguilled</h1>
            <div>
                <p class="sub-project-description">Projet toujours en cour de Développement fait 
                    sur le temps perso. À l'heure actuelle ou la sécurité, est devenu un domaine 
                    très compliqué, j'ai décidé de créer mon propre porte clé contenu en local 
                    camouflé de ses intentions par son nom et son logo qui n'évoque pas forcément 
                    son utilité première. J'ai essayé ici de créer une sécurité certes plus faible, 
                    mais bien mieux adapté à un utilisateur seul qui veut protéger son coffre-fort 
                    de mot de passe sans perdre l'accessibilité de ceux-ci a l'aide de question 
                    personnelle permettent l'accès au programme. Même si l'aspect reste extrêmement 
                    épuré et non finit et le concept mériterai quelque ajustement, on a ici un projet 
                    que j'ai à cœur de finir ne serai que pour mon utilisation personnelle.</p>

            </div>
            <div class="sub-project-div-img">
                <img src="../../public/assets/img/Project-Taguilled-Générateur.webp" alt="Image du générateur de mot de passe du projet Taguilled">
                <img src="../../public/assets/img/Project-Taguilled-Sécurité.webp" alt="Image du concepte de sécurité du projet Taguilled">
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
    name: 'ProjectOne',
    props: {
        section: Number,
    },
    methods: {
        goMainPage() {
            this.$emit("back");
        },
        goMainPagewithSection(section) {
            this.$emit("back", section);
        },
        navBurger() {
            this.$emit("navBurger");
        },
    },
    mounted() {
        let img = document.getElementsByTagName("img")[0];

        img.setAttribute("width", "65px");
    }, 
}
</script>