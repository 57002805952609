<template>
    <div v-if="section === 0">
        <div class="home" id="home">
            <header id="header">
                <div class="logo">
                    <a href="">
                        <img src="../../public/assets/img/logo-p-c.webp" alt="Logo" width="65">
                    </a>
                </div>
                <nav id="nav">
                    <ul>
                        <li>
                            <a href="" @click.prevent="backOnMainPage(1)">
                                <p class="nav-number">01</p>
                                <p class="nav-menu">// home</p>
                            </a>
                        </li>
                        <li>
                            <a href="" @click.prevent="backOnMainPage(2)">
                                <p class="nav-number">02</p>
                                <p class="nav-menu">// expertise</p>
                            </a>
                        </li>
                        <li>
                            <a href="" @click.prevent="backOnMainPage(3)">
                                <p class="nav-number">03</p>
                                <p class="nav-menu">// projet</p>
                            </a>
                        </li>
                        <li>
                            <a href="" @click.prevent="backOnMainPage(4)">
                                <p class="nav-number">04</p>
                                <p class="nav-menu">// contact</p>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div class="fake-right">
                    <button class="burger burger3" @click="navBurger">
                        <span></span>
                    </button>
                </div>
            </header>
            <div class="home-title">
                <h1 class="title-neon">PELETTE CHRISTOPHER</h1>
                <h3 class="sub-title">CONCEPTEUR DÉVELOPPEUR D'APPLICATIONS</h3>
            </div>
        </div>
        <div class="expertise" id="expertise">
            <div class="expertise-title">
                <h2>Mon expertise</h2>
            </div>
            <div class="expertise-first-div">
                <div class="expertise-left">
                    <div class="expertise-left-arrow"></div>
                </div>
                <div class="expertise-conception">
                    <div class="expertise-div-sub-title">
                        <div>
                            <img src="../../public/assets/img/icone-conception.webp" alt="icone conception">
                        </div>
                        <h4>Concepteur d'applications</h4>
                    </div>
                    <p>Expérience avec toute la partie Maquetage, UML et SysML de la conception d'applications. 
                        Écriture de cahier des charges et mise en place de cycle de développement ou de méthode 
                        agile pour le suivi de projet.</p>
                </div>
                <div class="expertise-right">
                    <div class="expertise-right-arrow"></div>
                </div>
            </div>
            <div class="expertise-second-div">
                <div class="expertise-web">
                    <div class="expertise-div-sub-title">
                        <div>
                            <img src="../../public/assets/img/icone-internet.webp" alt="icone internet">
                        </div>
                        <h4>Développeur web</h4>
                    </div>
                    <p>Savoir-faire du développeur "FullStack" que ce soit sur la technique du "back-end" avec 
                        l'utilisation des Framework comme Laravel ou Symfony ou encore sur le "front-end" avec 
                        cette fois le savoir-faire simultané de framework tel que Svelte, Vue.js ou de Javascript 
                        et de SASS.</p>
                </div>
                <div class="expertise-application">
                    <div class="expertise-div-sub-title">
                        <div>
                            <img src="../../public/assets/img/icone-applications.webp" alt="icone applications">
                        </div>
                        <h4>Développeur d'applications</h4>
                    </div>
                    <p>Expérimenté sur les langages de programmation Python et C# avec l'utilisation de Framework 
                        et librairies associés. Maitrise des technologie lié aux bases de données par l'utilisation 
                        de PostgreSQL ou MySQL.</p>
                </div>
            </div>
        </div>
        <div class="project" id="project">
            <div class="project-title">
                <h2>Mes projets</h2>
            </div>
            <div class="project-div-card">
                <div class="project-card">
                    <a href="" @click.prevent="moreDetails(1)">
                        <img src="../../public/assets/img/Projet-blog.webp" alt="Image du projet blog">
                        <div class="project-sub-card">
                            <p class="project-p-old">Projet Blog</p>
                            <p class="project-p-new">Détails</p>
                        </div>
                    </a>
                </div>
                <div class="project-card">
                    <a href="" @click.prevent="moreDetails(2)">
                        <img src="../../public/assets/img/projet-electro.webp" alt="Image du projet electro">
                        <div class="project-sub-card">
                            <p class="project-p-old">Projet electro</p>
                            <p class="project-p-new">Détails</p>
                        </div>
                    </a>
                </div>
                <div class="project-card">
                    <a href="" @click.prevent="moreDetails(3)">
                        <img src="../../public/assets/img/projet-taguilled.webp" alt="Image conceptuelle d'une application de sécurité">
                        <div class="project-sub-card">
                            <p class="project-p-old">Projet Taguilled</p>
                            <p class="project-p-new">Détails</p>
                        </div>
                    </a>
                </div>
                <div class="project-big-card">
                    <a href="" @click.prevent="moreDetails(4)">
                        <img src="../../public/assets/img/gamereveryday.webp" alt="Image de l'accueil de GamerEveryday">
                        <div class="project-sub-card">
                            <p class="project-p-old">GamerEveryday</p>
                            <p class="project-p-new">Détails</p>
                        </div>
                    </a>
                </div>
                <div class="project-big-card" id="project-ihdem">
                    <a href="" @click.prevent="moreDetails(5)">
                        <img src="../../public/assets/img/Project-IHDEM.webp" alt="Image de l'accueil du projet IHDEM">
                        <div class="project-sub-card">
                            <p class="project-p-old">Projet IHDEM</p>
                            <p class="project-p-new">Détails</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="contact" id="contact">
            <div class="contact-title">
                <h2>Conctatez-moi</h2>
            </div>
            <div class="contact-div-icon">
                <a href="mailto:christopher.pelette@gmail.com" class="contact-email">
                    <p>Mail</p>
                    <img src="../../public/assets/img/email.svg" alt="Icone Email" width="100">
                </a>
            </div>
            <div class="contact-div-icon">
                <a href="https://www.linkedin.com/in/christopher-pelette-795026235" class="contact-linkedin" onclick="window.open(this.href); return false;">
                    <p>Linkedin</p>
                    <img src="../../public/assets/img/linkedin.svg" alt="Icone Linkedin" width="100">
                </a>
            </div>
            <div class="contact-div-icon">
                <a href="https://github.com/didchrist" class="contact-github" onclick="window.open(this.href); return false;">
                    <p>Github</p>
                    <img src="../../public/assets/img/github.svg" alt="Icone Github" width="100">
                </a>
            </div>
            <div class="contact-legal">
                <p>Produit seul sans CMS © Copyright 2023 Christopher Pelette. Tout droits réservés.</p>
                <a class="nav-menu" href="" @click.prevent="moreDetails(6)">Mentions légales</a>
            </div>
        </div>
    </div>
    <ProjectBlog :section="section" v-else-if="section === 1" @back="backOnMainPage" @navBurger="navBurger"/>
    <ProjectElectro :section="section" v-else-if="section === 2" @back="backOnMainPage" @navBurger="navBurger"/>
    <ProjectTaguilled :section="section" v-else-if="section === 3" @back="backOnMainPage" @navBurger="navBurger"/>
    <ProjectGamerEveryday :section="section" v-else-if="section === 4" @back="backOnMainPage" @navBurger="navBurger"/>
    <ProjectIhdem :section="section" v-else-if="section === 5" @back="backOnMainPage" @navBurger="navBurger"/>
    <MentionLegales :section="section" v-else-if="section === 6" @back="backOnMainPage" @navBurger="navBurger"/>
</template>

<script>
import ProjectBlog from './ProjectBlog.vue';
import ProjectElectro from './ProjectElectro.vue';
import ProjectTaguilled from './ProjectTaguilled.vue';
import ProjectGamerEveryday from './ProjectGamerEveryday.vue';
import ProjectIhdem from './ProjectIhdem.vue';
import MentionLegales from './MentionLegales.vue';


export default {
    name: 'MainPage',
    data() {
        return {
            section: 0,
            isNavFixed: false,
            timeout: null,
        }
    },
    methods: {
        async moreDetails(project) {     
            this.section = project;

            await this.$nextTick();

            window.scrollTo(0,0);
        },
        async backOnMainPage(part = 3) {
            this.section = 0;

            await this.$nextTick();
            const nav = document.getElementById("nav");

            const burger3 = document.querySelector(".burger3");
            const header = document.getElementsByTagName("header")[0];

            switch (part) {
                case 1:
                    nav.classList.remove("transition-nav");
                    nav.classList.remove("fixed");

                    window.scrollTo(0, document.getElementById('home').offsetTop);

                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
                    break;
                case 2:
                    window.scrollTo(0, document.getElementById('expertise').offsetTop);

                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
                    break;
                case 3:
                    window.scrollTo(0, document.getElementById('project').offsetTop + 300);
                    
                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
                    break;
                case 4:
                    window.scrollTo(0, document.getElementById('contact').offsetTop);
                    
                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
                    break;
                case 5:
                    window.scrollTo(0, document.getElementById('project-ihdem').offsetTop);

                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
                    break;

                case 6:
                    window.scrollTo(0, document.getElementById('contact').offsetTop);

                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
                    break;
                default:
                    window.scrollTo(0, document.getElementById('project').offsetTop + 300);
                    
                    if (burger3.classList[2] === "on") {
                        burger3.classList.remove("on");
                        header.classList.remove("active");
                    }
            }
            
        },
        async handleScroll() {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }
            if (this.section === 0) {
                const nav = document.getElementById("nav");
                const isLargeScreen = window.matchMedia("(min-width: 800px)").matches;
    
                if (isLargeScreen) {
                    if (window.scrollY > 400 && !this.isNavFixed) {
    
                        this.timeout = window.setTimeout(() => {
                            nav.classList.add("fixed");
                            this.isNavFixed = true;
                        }, 100);
    
                        nav.classList.add("transition-nav");
    
                    } else if (window.scrollY <= 400 && this.isNavFixed) {
                        nav.classList.remove("transition-nav");
                        nav.classList.remove("fixed");
                        this.isNavFixed = false;
                    }
                } else {
                    nav.classList.remove("transition-nav");
                    nav.classList.remove("fixed");
                }
            }
        },
        navBurger() {
            let burger3 = document.querySelector(".burger3");
            burger3.classList.toggle("on");

            let header = document.getElementsByTagName("header")[0];
            header.classList.toggle("active");
        },
    },
    components: { ProjectBlog, ProjectElectro, ProjectTaguilled, ProjectGamerEveryday, ProjectIhdem, MentionLegales, },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        let img = document.getElementsByTagName("img")[0];

        img.setAttribute("width", "65");
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}

</script>