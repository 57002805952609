<template>
    <div class="sub-project">
        <header>
          <div class="logo">
              <a href="">
                  <img class="js-img" src="../../public/assets/img/logo-p-c.webp" alt="Logo" width="65px">
              </a>
          </div>
          <nav>
              <ul>
                  <li>
                      <a href="#home" @click.prevent="goMainPagewithSection(1)" id="link-home">
                          <p class="nav-number">01</p>
                          <p class="nav-menu">// home</p>
                      </a>
                  </li>
                  <li>
                      <a href="#expertise" @click.prevent="goMainPagewithSection(2)">
                          <p class="nav-number">02</p>
                          <p class="nav-menu">// expertise</p>
                      </a>
                  </li>
                  <li>
                      <a href="#project" @click.prevent="goMainPagewithSection(3)">
                          <p class="nav-number">03</p>
                          <p class="nav-menu">// projet</p>
                      </a>
                  </li>
                  <li>
                      <a href="#contact" @click.prevent="goMainPagewithSection(4)">
                          <p class="nav-number">04</p>
                          <p class="nav-menu">// contact</p>
                      </a>
                  </li>
              </ul>
          </nav>
          <div class="fake-right">
                    <button class="burger burger3" @click="navBurger">
                        <span></span>
                    </button>
            </div>
        </header>
        <a class="sub-project-back" href="" @click.prevent="goMainPage">
            <span class="sub-project-back-span"></span>
            <span class="sub-project-back-color"></span>
        </a>
        <div class="sub-project-content">
            <p class="sub-project-type">Développement web</p>
            <h1>Projet Blog</h1>
            <div>
                <p class="sub-project-description">Projet de site blog classé par catégorie avec un système de login et de changement de contenu par utilisateur associé a son contenu. Ce projet à était realisé en architecture MVC avec du PHP pur et des script javascript réalisé soi-meme.</p>

            </div>
            <div class="sub-project-div-img">
                <img src="../../public/assets/img/Projet-blog.webp" alt="Image du projet Blog">
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
    name: 'ProjectOne',
    props: {
        section: Number,
    },
    methods: {
        goMainPage() {
            this.$emit("back");
        },
        goMainPagewithSection(section) {
            this.$emit("back", section);
        },
        navBurger() {
            this.$emit("navBurger");
        },
    },
    mounted() {
        let img = document.getElementsByTagName("img")[0];

        img.setAttribute("width", "65px");
    }, 
}
</script>