<template>
    <div class="sub-project">
        <header>
            <div class="logo">
                <a href="">
                    <img class="js-img" src="../../public/assets/img/logo-p-c.webp" alt="Logo" width="65px">
                </a>
            </div>
            <nav>
                <ul>
                    <li>
                        <a href="#home" @click.prevent="goMainPagewithSection(1)" id="link-home">
                            <p class="nav-number">01</p>
                            <p class="nav-menu">// home</p>
                        </a>
                    </li>
                    <li>
                        <a href="#expertise" @click.prevent="goMainPagewithSection(2)">
                            <p class="nav-number">02</p>
                            <p class="nav-menu">// expertise</p>
                        </a>
                    </li>
                    <li>
                        <a href="#project" @click.prevent="goMainPagewithSection(3)">
                            <p class="nav-number">03</p>
                            <p class="nav-menu">// projet</p>
                        </a>
                    </li>
                    <li>
                        <a href="#contact" @click.prevent="goMainPagewithSection(4)">
                            <p class="nav-number">04</p>
                            <p class="nav-menu">// contact</p>
                        </a>
                    </li>
                </ul>
            </nav>
            <div class="fake-right">
                    <button class="burger burger3" @click="navBurger">
                        <span></span>
                    </button>
            </div>
        </header>
        <a class="sub-project-back" href="" @click.prevent="goMainPagewithSection(6)">
            <span class="sub-project-back-span"></span>
            <span class="sub-project-back-color"></span>
        </a>
        <div class="legal-content">
            <h3>Mentions légales</h3>
    
            <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site PortFolio l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
    
            <h4>Edition du site </h4>
            <p>Le présent site, accessible à l’URL https://christopherpelette.com/ (le « Site »), est édité par :</p>
            <p>Christopher Pelette, résidant 58 rue du stade 16170 ROUILLAC, de nationalité Française (France), né(e) le 24/07/1995,</p>
    
            <h4>Hébergement</h4>
    
            <p>Le Site est hébergé par la société Infomaniak, situé Rue Eugène-Marziano 25, 1227 Genève, Suisse, (contact téléphonique ou email : (+41) 22 820 35 44).</p>
    
            <h4>Directeur de publication </h4>
    
            <p>Le Directeur de la publication du Site est Christopher Pelette.</p>
    
            <h4>Nous contacter</h4>
    
            <p>Par téléphone : +33624006172</p>
            <p>Par email : christopher.pelette@gmail.com</p>
            <p>Par courrier : 58 rue du stade 16170 ROUILLAC</p>
            <p>Génération des mentions légales par <a href="https://www.legalstart.fr/"> Legalstart</a>.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MentionLegales',
    props: {
        section: Number,
    },
    methods: {
        goMainPage() {
            this.$emit("back");
        },
        goMainPagewithSection(section) {
            this.$emit("back", section);
        },
        navBurger() {
            this.$emit("navBurger");
        },
    },
}
</script>